
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

body{
  font-family: 'Poppins';
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


.app__navbar {
  overflow: hidden;
  margin: 100;
  padding: 1000;
  background-color:rgb(23, 49, 82);
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color:rgb(23, 49, 82);

  img{
      width: 100%;
      height: 100px;
  }
}
@media (max-width: 1200px) {
  .app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color:rgb(23, 49, 82);
  
    img{
        width: 450px;
        height: 50px;
    }
  }
 
}

.app__navbar-links {
  flex: 1;
  display: flex;
  overflow: hidden;
  margin: 15px;
  color: rgb(240, 241, 255);
  text-decoration: none;
  &:hover{
             color: var(--secondary-color);
        }
}

.app__navbar-menu{
  width: 35px;
  height: 35px;
  border-radius: 50px;
  position: relative;
  display: flex;
  background-color:rgb(23, 49, 82);

  // svg{
  //     width: 35px;
  //     height: 35px;
  //     background-color:rgb(23, 49, 82);
  //     //color: var(--white-color); 
  // }
  div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
  
      z-index: 5;
      padding: 1rem;
      width: 20%;
      height: 50vh;

      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;

      background-color:rgb(23, 49, 82);
      background-size: cover;
      background-repeat: repeat;
 
      @media screen and (min-width:900px) {
           display: none;
          
      }
  }
  @media screen and (min-width:900px) {
       display: none;
  }
}

